<template>
  <v-layout column style="width: 500px">
    <div v-if="isCustomerSelected && showButtons && isCustomerEditEnabled" class="padding-x-13 margin-b-0">
      <CustomerSignUpEmailDetail
        :customer="tripContact"
      />
      <v-divider />
    </div>
    <TripContactSidebarUserSelector
      :id="`trip-contact-sidebar-user-selector`"
      v-model="tripContact"
      :user-title="'Trip Contact'"
      style="margin: -10px 0 -20px -14px"
      @set-trip-contact="setTripContact"
      @show-buttons="(value) => (showButtons = value)"
    />
    <div style="height: 142px; width: 100%" />
    <v-btn
      v-if="contactId && showButtons"
      :id="`trip-contact-sidebar-close-dialog-button`"
      color="error"
      outline
      style="margin: 0 0 71px 0; border-radius: 0"
      class="trip-contact-sidebar--action-btn trip-contact-sidebar--delete-btn"
      @click="deleteTripContact"
    >
      Remove Contact
    </v-btn>
    <v-btn
      v-if="showButtons"
      :id="`trip-contact-sidebar-save-button`"
      :disabled="!isCustomerSelected"
      class="trip-contact-sidebar--action-btn"
      :class="{
        'trip-contact-sidebar--action-btn--disabled': !isCustomerSelected,
      }"
      color="primary"
      :style="`margin: 0; border-radius: 0; background-color: ${
        isCustomerSelected ? '#3B9CF1' : '#ACCBE3'
      } !important;`"
      @click="save"
    >
      Save
    </v-btn>
  </v-layout>
</template>
<script>
import CustomerSignUpEmailDetail from './CustomerSignUpEmailDetail.vue'
import TripContactSidebarUserSelector from './TripContactSidebarUserSelector.vue'
import { mapGetters, mapActions } from 'vuex'
import customers from '@/services/customers'
import { SplitFeatureFlag } from '@/utils/enum'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    CustomerSignUpEmailDetail,
    TripContactSidebarUserSelector,
  },
  provide: {
    isInSidebarDialog: true,
  },
  props: {
    value: {
      type: Boolean,
    },
    tripId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tripContact: null,
      showButtons: true,
      isCustomerEditEnabled: false,
    }
  },
  computed: {
    ...mapGetters({
      showAddTripContactNote: 'dispatchNew/showAddTripContact',
    }),
    contactId() {
      return this.tripContact?.id
    },
    isCustomerSelected() {
      return !!this.contactId
    },
  },
  created() {
    this.tripContact = this.value
  },
  async mounted() {
    this.isCustomerEditEnabled = await this.isFeatureEnabled(SplitFeatureFlag.MainContactEdit)
  },
  methods: {
    ...mapActions({
      setShowAddTripContact: 'dispatchNew/setShowAddTripContact',
      showAlert: 'app/showAlert',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async deleteTripContact() {
      const params = {
        tripId: this.tripId,
        payload: {
          tripContact: {
            customerId: null,
          },
        },
      }

      try {
        await this.$store.dispatch('trips/editTripContact', params)
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error setting trip contact, please try again.',
        })
        console.error(err)
      }
      EventBus.$emit('refresh-detail')
      EventBus.$emit('trip-contact-update', null)
      this.close()
    },
    async save() {
      const params = {
        tripId: this.tripId,
        payload: {
          tripContact: {
            customerId: this.contactId,
          },
        },
      }

      try {
        await this.$store.dispatch('trips/editTripContact', params)
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error setting trip contact, please try again.',
        })
        console.error(err)
      }
      EventBus.$emit('refresh-detail')
      EventBus.$emit('trip-contact-update', this.value)
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
      this.setShowAddTripContact(false)
    },
    async setTripContact(tripContact) {
      if (tripContact.id) {
        const customerResponse = await customers.getCustomer(tripContact.id)
        this.tripContact = customerResponse?.data?.customer
        this.tripContact.id = this.tripContact.customerId
      } else {
        this.tripContact = tripContact
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.trip-contact-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--textbox {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    border: none;
    background-color: white !important;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    &.v-btn--disabled {
      background-color: $accent !important;
    }
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
